<template>
  <div class="row mt-1" style="line-height: normal">
    <div
      class="d-flex mr-2"
      v-for="flugverfuegbarkeit in latestFVCRequests"
      :key="'flugverfuegbarkeit-' + flugverfuegbarkeit.id"
      :id="'flugverfuegbarkeit-' + flugverfuegbarkeit.id"
    >
      <div>
        <div class="aufpreis rounded" :class="istBgColor(flugverfuegbarkeit)">
          {{
            flugverfuegbarkeit.aufpreis !== null
              ? flugverfuegbarkeit.aufpreis === 0
                ? 'J'
                : flugverfuegbarkeit.aufpreis + '€'
              : flugverfuegbarkeit.status === 'AufAnfrage'
              ? 'RQ'
              : 'N'
          }}
        </div>
        <div class="text-center deutscherAbflughafen">
          {{ flugverfuegbarkeit.deutscherAbflughafenIataCode }}
        </div>
      </div>
      <b-tooltip
        :target="'flugverfuegbarkeit-' + flugverfuegbarkeit.id"
        @show="ladeLetzteBuchungsantworten(flugverfuegbarkeit)"
        custom-class="custom-tooltip-fvc-widget"
        placement="left"
      >
        <br />
        <b-spinner v-if="isLoadingLetzteBuchungsantworten"></b-spinner>
        <div v-else-if="letzteBuchungsantworten.length > 0">
          <div class="mb-2">
            Abfragealter:
            <b>{{ daysSince(flugverfuegbarkeit.abfragedatum) }}</b>
          </div>
          Letzte Buchungsantworten:
          <LineChart
            style="width: 300px; height: 200px; margin: 0; padding: 0"
            :datapoints="letzteBuchungsantworten"
            :showLegend="false"
          ></LineChart>
        </div>
      </b-tooltip>
    </div>
  </div>
</template>
<script>
import { differenceInDays, parseISO, isAfter, isToday, format } from 'date-fns';
import * as odataService from '@/core/common/services/odata.service';
import LineChart from '@/components/flugverfuegbarkeit/line-chart.vue';

export default {
  props: {
    flugverfuegbarkeiten: { type: Array },
    data: { type: Object },
  },
  components: { LineChart },

  data() {
    return {
      letzteBuchungsantworten: [],
      isLoadingLetzteBuchungsantworten: false,
    };
  },
  computed: {
    latestFVCRequests() {
      const newestEntries = new Map();

      // Iterate through the data
      for (const entry of this.flugverfuegbarkeiten) {
        const iataCode = entry.deutscherAbflughafenIataCode;
        const entryDate = parseISO(entry.abfragedatum);

        // Check if iataCode is already in the Map
        if (!newestEntries.has(iataCode)) {
          newestEntries.set(iataCode, entry);
        } else {
          // Compare dates to find the newest entry
          const currentNewestEntry = newestEntries.get(iataCode);
          if (isAfter(entryDate, parseISO(currentNewestEntry.abfragedatum))) {
            newestEntries.set(iataCode, entry);
          }
        }
      }
      const newestEntriesList = Array.from(newestEntries.values());

      if (this.flugverfuegbarkeiten) {
        const sortOrder = ['FRA', 'MUC', 'DUS', 'BER', 'HAM', 'VIE', 'ZRH'];
        const fvcData = newestEntriesList;
        return fvcData.sort((a, b) => {
          const codeA = sortOrder.indexOf(a.deutscherAbflughafenIataCode);
          const codeB = sortOrder.indexOf(b.deutscherAbflughafenIataCode);

          return codeA - codeB;
        });
      } else return null;
    },
  },
  methods: {
    ladeLetzteBuchungsantworten(flugverfuegbarkeit) {
      this.letzteBuchungsantworten = [];
      this.isLoadingLetzteBuchungsantworten = true;
      const {
        deutscherAbflughafenIataCode,
        abfragedatum,
        zielflughafenIataCode,
        abflughafenIataCode,
        zimmertyp,
        verpflegung,
      } = flugverfuegbarkeit;
      const filterString = `reisetermin/reiseterminkuerzel eq '${this.data.reiseterminkuerzel}' and deutscherAbflughafenIataCode eq '${deutscherAbflughafenIataCode}' and abfragedatum le ${abfragedatum} and zielflughafenIataCode eq '${zielflughafenIataCode}' and abflughafenIataCode eq '${abflughafenIataCode}' and zimmertyp eq '${zimmertyp}' and verpflegung eq '${verpflegung}'`;
      odataService
        .getFlugverfuegbarkeit({
          filter: filterString,
          top: 5,
          expand: { reisetermin: { expand: { reise: {} } } },
          orderBy: 'abfragedatum desc',
        })
        .then(response => {
          this.letzteBuchungsantworten = response.data.map(item => ({
            ...item,
            abfragedatum: format(parseISO(item.abfragedatum), 'dd.MM.yyyy'),
          }));
          this.isLoadingLetzteBuchungsantworten = false;
          console.log(this.letzteBuchungsantworten);
        })
        .catch(_ => (this.isLoadingLetzteBuchungsantworten = false));
    },
    istBgColor(flugverfuegbarkeit) {
      if (flugverfuegbarkeit.status === 'NichtVerfuegbar' || flugverfuegbarkeit.aufpreis > 200) return 'red';
      else if (flugverfuegbarkeit.status === 'Verfuegbar') return 'green';
      else if (flugverfuegbarkeit.status === 'AufAnfrage') return 'gray';
    },
    daysSince(dateString) {
      const inputDate = parseISO(dateString);
      const currentDate = new Date();

      // If the input date is today, the difference will be 0
      if (isToday(inputDate)) {
        return '0 Tage';
      }

      const daysDifference = differenceInDays(currentDate, inputDate);
      return daysDifference === 1 ? '1 Tag' : daysDifference + ' Tage';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';

.aufpreis {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 45px;
  height: 25px;
  font-size: 1rem;
}

.red {
  background-color: $danger;
}
.yellow {
  background-color: $warning;
}
.darkgreen {
  background-color: darken($success, 10%);
}
.greenalert {
  background-color: $success;
}
.green {
  background-color: $success;
}
.gray {
  background-color: #e5eaee;
}
.deutscherAbflughafen {
  font-size: 0.9rem;
  line-height: 15px;
}
.tooltip {
  opacity: 1 !important;
}
</style>
<style>
.custom-tooltip-fvc-widget > .tooltip-inner {
  max-width: none;
  width: 500;
  font-size: 14px;
}
</style>
